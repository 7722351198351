<template>
  <div>
    <div>
      <div
        v-if="!busy"
        class="list-title clicable"
        @click.stop.prevent="nextOrder"
        :title="$t('sort')"
      >
        {{
          $t(
            remoteScreen.show ? "titles.base_screen" : "titles.gallery_panels"
          )
        }}:
        <span class="" style="position: absolute; right: 2px; font-size: 85%">
          <i
            class="fa"
            :class="
              [
                ' fa-sort-amount-desc',
                'fa-sort-alpha-asc',
                'fa-sort-alpha-desc'
              ][order + 1]
            "
            style=""
          ></i>
        </span>
      </div>
    </div>
    <InputSearch v-model="queryString" v-if="!remoteScreen.show">
      <template #addonAfter>
        <div
          class="input-group-addon btn"
          :title="$t('hints.linked_panel_selector')"
          @click.stop.prevent="toggleRemoteScreen()"
        >
          <i class="fa fa-desktop"></i>
        </div>
      </template>
    </InputSearch>
    <ScreenSelector
      v-else
      ref="remoteScreenInp"
      v-model="remoteScreenId"
      :except="[screenId]"
      @close="resetRemoteScreen"
      class="form-group-sm"
      style="margin: -5px 0 0 0"
    >
      <template #addonBefore>
        <div class="input-group-addon" :title="$tc('screen', 1)">
          <i class="fa fa-desktop text-primary"></i>
        </div>
      </template>
      <template #addonAfter>
        <div
          class="input-group-addon btn"
          :title="$t('titles.gallery_panels')"
          @click.stop.prevent="resetRemoteScreen"
        >
          <i class="fa fa-undo"></i>
        </div>
      </template>
    </ScreenSelector>
    <div
      style="
        clear: both;
        margin-top: 15px;
        min-height: 100px;
        position: relative;
      "
    >
      <div class="text-center" v-if="busy" style="margin-top: 10px">
        <i class="fa fa-refresh fa-spin"></i>
      </div>
      <template v-else>
        <div v-if="list.length" style="margin-top: 10px">
          <div v-if="this.remoteScreen.show" style="">
            {{ $t("titles.base_screen_panels") }}
          </div>
          <section v-if="view == 'tree'">
            <TogglePanel
              v-for="group in groupedList"
              :key="group.name"
              :title="$t(`category.${group.name}`)"
              :persistent="`${$options.name}_${group.name}`"
              layout="compressed"
              accordion="panel_gallery"
            >
              <div
                v-for="(item, ix) in group.list"
                class="item panel-gallery"
                :class="[
                  view,
                  (remoteScreen.show && !remoteScreen.screen) || item.disabled
                    ? 'disabled'
                    : 'clicable'
                ]"
                :key="ix"
                @click.stop.prevent="active = ix"
                @dblclick.stop.prevent="!item.disabled && onAddItem(item)"
                @dragstart="
                  !item.disabled &&
                    jsonDragger.onDragStart($event, 'panel', item)
                "
                @dragend="jsonDragger.onDragEnd($event)"
                @dragover="jsonDragger.onDragOver($event)"
                :title="`${$t(item.title)}\n${$t(
                  'double_click_or_drag_to_insert',
                  { item: 'layout' }
                )}${
                  item.disabled ? '\n' + $t('hints.just_one_may_be_placed') : ''
                }`"
              >
                <div>
                  <img :src="item.thumbnail" />
                  <span class="small" draggable="true">
                    {{ $t(item.title) }}
                    <PanelPosition
                      v-if="remoteScreen.template"
                      :panelName="item.template.name"
                      :template="remoteScreen.template"
                    />
                  </span>
                </div>
              </div>
            </TogglePanel>
          </section>
          <section v-else>
            <div
              v-for="(item, ix) in list"
              class="item panel-gallery"
              :class="[
                view,
                (remoteScreen.show && !remoteScreen.screen) || item.disabled
                  ? 'disabled'
                  : 'clicable'
              ]"
              :key="ix"
              @click.stop.prevent="active = ix"
              @dblclick.stop.prevent="!item.disabled && onAddItem(item)"
              @dragstart="
                !item.disabled && jsonDragger.onDragStart($event, 'panel', item)
              "
              @dragend="jsonDragger.onDragEnd($event)"
              @dragover="jsonDragger.onDragOver($event)"
              :title="`${$t(item.title)}\n${$t(
                'double_click_or_drag_to_insert',
                { item: 'layout' }
              )}${
                item.disabled ? '\n' + $t('hints.just_one_may_be_placed') : ''
              }`"
            >
              <div>
                <img :src="item.thumbnail" />
                <span class="small" draggable="true">
                  {{ $t(item.title) }}
                  <PanelPosition
                    v-if="remoteScreen.template"
                    :panelName="item.template.name"
                    :template="remoteScreen.template"
                  />
                </span>
              </div>
            </div>
          </section>
        </div>
        <div v-else class="text-center empty-list">
          {{ $t("no_panels_available") }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Panels from "@/assets/dashboard/panels.json";
import InputSearch from "@/components/widgets/input-search.vue";
import ScreenSelector from "@/components/editor/screen-selector.vue";
import DashboardService from "@/services/dashboard.js";
import PanelPosition from "@/components/control-sidebar/property-editors/panel-position.vue";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import messages from "@/i18n/dashboard-panels.js";
import { editorSettings } from "@/services/dashboard";

export default {
  name: "PanelGallery",
  props: {
    view: {
      type: String,
      required: false,
      default: "list"
    }
  },
  components: {
    InputSearch,
    ScreenSelector,
    PanelPosition,
    TogglePanel
  },
  data: () => ({
    active: 0,
    queryString: "",
    busy: false,
    remoteScreen: {
      show: false,
      template: null,
      screen: null
    },
    groups: [
      { name: "real_time", collapsed: true },
      { name: "history", collapsed: true },
      { name: "report", collapsed: true },
      { name: "customization", collapsed: true },
      { name: "utils", collapsed: true }
    ],
    order: -1
  }),
  computed: {
    screenId() {
      return this.$store.getters["dashboard/draft"]?.screenId || "";
    },
    template() {
      return this.$store.getters["dashboard/draft"]?.template || null;
    },
    orderBy() {
      return this.order < 0 ? "index" : "lt"
    },
    list() {
      let usedPanels = this.$utils.distinct(
        (this?.template?.panels || []).map(({ template }) => template)
      );
      let o = [
        [1, -1], // asc
        [-1, 0] // dsc
      ][this.order < 1 ? 0 : 1];
      let lst;
      if (
        this.remoteScreen.screen &&
        this.remoteScreen?.template?.panels?.length
      ) {
        let refPanel;
        lst = this.remoteScreen.template.panels
          .map((p) => {
            refPanel = (Panels || []).find(
              ({ template }) => template.template == p.template
            );
            if (refPanel) {
              if (
                !this.$root.config.panels.some((name) => name == refPanel.name)
              ) {
                return null;
              }
              let panel = JSON.parse(JSON.stringify(refPanel));
              panel.lt = this.$t(panel.title);
              panel.template = p;
              // Important:
              // It must avoid multi-level remote screen chain
              panel.linkedScreen = (this?.remoteScreen?.template
                ?.linkedPanels || {})[p.name] || {
                screenId: this.remoteScreen.screen.id,
                etag: this.remoteScreen.screen.etag,
                syncEnabled: true
              };
              panel.disabled = refPanel.template.template === "EquipmentEmptyPanel" ||
                (p.name in (this?.template?.linkedPanels || {})) ||
                (panel.singleton && usedPanels.indexOf(panel.template.template) != -1);
              return panel;
            }
            return null;
          })
          .filter((i) => i != null);
      } else {
        if (this.remoteScreen.show) {
          return []
        }
        lst = (Panels || []).filter((panel) => {
          var ret = true;
          if (
            !panel.category || !this.groups.some(({ name }) => name == panel.category)
          ) {
            return;
          }
          panel.disabled = (
            panel.singleton &&
            usedPanels.indexOf(panel.template.template) != -1
          );
          panel.lt = this.$t(panel.title);
          ret = this.$root.config.panels.some((name) => name == panel.name);
          if (!ret) return false;
          if (this.queryString) {
            ret = this.$utils.queryStrAtr(this.queryString, {
              a: panel.lt
            });
          }
          return ret;
        });
      }
      return (lst || []).sort((a, b) => (a[this.orderBy] > b[this.orderBy] ? o[0] : a[this.orderBy] < b[this.orderBy] ? o[1] : 0));
    },
    remoteScreenId: {
      set(value) {
        if (this.remoteScreenId && !value) {
          this.resetRemoteScreen();
        }
        const screen = this.$store.getters["dashboard/screens"].find(
          ({ id }) => id == value
        );
        if (screen) {
          this.remoteScreen.screen = screen;
          this.fetchLinkedScreen();
        }
      },
      get() {
        return this?.remoteScreen?.screen?.id || "";
      }
    },
    groupedList() {
      return this.groups.map((group) => ({
        ...group,
        list: this.list.filter((panel) => panel.category == group.name)
      })).filter(({ list }) => list.length > 0);
    }
  },
  watch: {
    order(n, o) {
      let entry = editorSettings();
      entry[this.$options.name] = entry[this.$options.name] || {};
      entry[this.$options.name]["order"] = n;
      editorSettings(entry);
    }
  },
  methods: {
    onAddItem(item) {
      const layout = (this?.template?.layout || null);
      const panels = (this?.template?.panels || null);
      if (!layout || !panels) return;
      let ir, ic, ip;
      for (ir in layout || []) {
        for (ic in layout[ir] || []) {
          ip = (layout[ir][ic]?.panels || []).findIndex((panelName) =>
            panels.some(({ name, template }) => name === panelName && template == 'EquipmentEmptyPanel')
          );
          if (ip >= 0) {
            break;
          }
        }
        if (ip >= 0) break;
      }
      // console.log(`${ir},${ic},${ip}`);
      if (ip >= 0) {
        let panel = JSON.parse(JSON.stringify(item.template));;
        let linkedScreen = item.linkedScreen
          ? JSON.parse(JSON.stringify(item.linkedScreen))
          : null;
        if (!linkedScreen && !item.singleton) {
          panel.name = this.$utils.uuid();
        }
        this.$root.$emit("dashboard:editor", {
          action: "setPanelAt",
          data: {
            row: ir,
            col: ic,
            pos: ip,
            panel: panel,
            linkedScreen: linkedScreen
          }
        });
        this.$nextTick(() => {
          this.$root.$emit("panel:resized");
        });
      }
    },
    toggleRemoteScreen() {
      this.remoteScreen.show = !this.remoteScreen.show;
    },
    resetRemoteScreen() {
      this.remoteScreen.show = false;
      this.remoteScreen.template = null;
      this.remoteScreen.screen = null;
    },
    async fetchLinkedScreen() {
      if (!this?.remoteScreen?.screen?.path) return;
      let template = window.localStorage.getItem(
        `dashboard(${this.remoteScreen.screen.id})`
      );
      if (template && typeof template == "string") {
        template = JSON.parse(template);
      }
      if (template && template?.draft?.etag == this.remoteScreen.screen.etag) {
        this.remoteScreen.template = template;
      } else {
        this.busy = true;
        const srv = new DashboardService();
        srv.getFileContent(this.remoteScreen.screen.path).then((template) => {
          this.busy = false;
          try {
            if (template) {
              this.remoteScreen.template = JSON.parse(template);
            }
          } catch (error) {
            console.log("invalid JSON format from remote screen");
          }
        });
      }
    },
    nextOrder() {
      this.order = (this.order > 0) ? -1 : this.order + 1;
    }
  },
  created() {
    this.resetRemoteScreen();
    this.jsonDragger = this.$utils.JSONDragger({
      onDragging: (o) => this.$store.dispatch("dashboard/dragging", o ? "panel" : "")
    });
    // restore
    let entry = editorSettings();
    let prv = entry[this.$options.name];
    if (prv) {
      this.order = prv.order ?? this.order;
    }
  },
  beforeDestroy() {
    delete this.jsonDragger;
  },
  i18n: { messages }
};
</script>

<style scoped>
.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}
.skin-dark .clicable:hover {
  opacity: 1;
}
div.item {
  margin-bottom: 3px;
  font-weight: 600;
  color: #555;
}

.skin-dark div.item {
  color: var(--skin-dark-light);
}

div.disabled,
div.disabled:hover {
  cursor: not-allowed;
  color: #888;
}

div.disabled img {
  filter: grayscale(1);
  opacity: 0.6;
}

div.empty-list {
  margin-top: 10px;
  padding: 8px 0;
  border: 1px solid lightgray;
  border-radius: 5px;
}

div.inline {
  display: inline-block;
  margin: 4px 10px;
  max-width: 50px;
  max-height: 90px;
  border-radius: 5px;
  text-align: center;
  vertical-align: top;
  position: relative;
  overflow: normal;
  line-height: 0.6em;
}

div.inline > div > img {
  width: 100%;
  display: inline-block;
  vertical-align: top;
}

div.inline > div > span {
  font-size: 76%;

  white-space: normal;
}

div.list-title {
  position: relative;
  font-size: 10pt;
  margin: 0px 0 3px 0;
  font-weight: 700;
  color: #737a81;
}

.skin-dark div.list-title {
  color: var(--skin-dark-light);
}

div.list-title:hover {
  color: #337ab7;
}

.skin-dark div.list-title:hover {
  color: #fff;
}

div.list > div > img,
div.tree > div > img {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

div.list > div > span,
div.tree > div > span {
  vertical-align: middle;
  margin-left: 4px;
  white-space: nowrap;
  width: calc(100% - 30px);
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  text-overflow: ellipsis;
}

.form-group-sm::v-deep > .input-group > .v-select > div {
  height: 30px;
}

.skin-dark .panel-gallery img {
  filter: grayscale(0.9) brightness(200%);
}

.skin-dark .panel-gallery:hover {
  color: #fff;
}

.skin-dark .panel-gallery:hover img {
  filter: grayscale(1) brightness(300%);
}
</style>
