<template>
  <section v-if="entry">
    <div class="form-group form-group-sm">
      <label for=""
        >{{ $tc("titles.named_query", 1) }}
        <Tooltip :title="$t('hints.named_query')" />
      </label>
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          :title="entryName"
          v-model="entryName"
          :placeholder="$t('name')"
        />
        <span
          class="input-group-addon btn"
          :title="$t('copy', {item: entryName})"
          :disabled="entryName == ''"
          @click.stop.prevent="copyToClipboard()"
        >
          <i class="fa fa-copy text-warning"></i>
        </span>
        <span
          class="input-group-addon btn"
          @click.stop.prevent="$emit('cancel')"
          :title="$t('close')"
        >
          <i class="fa fa-close"></i>
        </span>
      </div>
    </div>
    <div style="border: 1px solid lightgray; border-radius: 3px; padding: 3px">
      <!-- begin group by expression -->
      <div class="form-group form-group-sm">
        <div style="position: relative">
          <label for="">
            <i class="fa fa-key"></i> {{ $t("titles.group_by_expression") }}
            <Tooltip :title="$t('hints.group_by_expression')" />
          </label>
          <span
            style="position: absolute; right: 2px; font-size: 85%"
            v-if="groupByExpression"
            @click.stop.prevent="
              groupByOrder = groupByOrder == 'asc' ? 'desc' : 'asc'
            "
            :title="$tc(groupByOrder == 'asc' ? 'ascendent' : 'descendent')"
          >
            <i
              :class="
                groupByOrder == 'asc'
                  ? 'fa clicable fa-sort-alpha-asc text-primary'
                  : 'fa clicable fa-sort-alpha-desc text-danger'
              "
            ></i>
          </span>
        </div>
        <JSONPathPicker
          :entry="defGroupByEntry"
          :append="true"
          entryType=""
          v-model="groupByExpression"
        />
      </div>
      <!-- end group by expression -->

      <!-- begin filter expression -->
      <div class="form-group form-group-sm">
        <div style="position: relative">
          <label for="">
            <i class="fa fa-filter"></i> {{ $tc("filter", 1) }}
            <Tooltip :title="$t('hints.filter_expression')" />
          </label>
        </div>
        <JSONPathPicker
          :entry="defGroupByEntry"
          :append="true"
          entryType=""
          placeholder="value > 50"
          v-model="filterExpression"
        />
      </div>
      <!-- end filter expression -->

      <!-- begin column list -->
      <div class="columns-container">
        <DataListForm
          v-model="entry.dataList"
          addNewText="add"
          :labelSelection="$t('columns')"
          :selectable="false"
          :collapsed="false"
          :itemsCollapsed="true"
          :dataListParser="dataListParser"
          :draggable="false"
          :showAddAllDataButton="false"
          :allowDupplicated="true"
          :dataSelectionOnTop="false"
          @item:added="onNewColumn"
        >
          <template v-slot:item="entry">
            <div class="column-form">
              <!-- begin column name -->
              <div class="form-group form-group-sm">
                <label for="">{{ $t("titles.column_name") }}</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    :value="dataItemProperty(entry.item, 'columnName')"
                    :placeholder="getDataNameAt(entry.item.index)"
                    @input="
                      dataItemProperty(
                        entry.item,
                        'columnName',
                        $event.target.value
                      )
                    "
                  />
                  <div
                    class="input-group-addon btn"
                    @click.stop.prevent="
                      onResetItem($event, 'columnName', entry.item)
                    "
                  >
                    <i class="fa fa-close"></i>
                  </div>
                </div>
                <div
                  class="small text-warning"
                  :title="getDataTitleAt(entry.item.index)"
                >
                  <i
                    class="glyphicon glyphicon-stats"
                    style="font-size: 70%:cursor:help"
                  ></i>
                  <span style="vertical-align: text-top">
                    {{ getDataNameAt(entry.item.index) }}
                  </span>
                </div>
              </div>
              <!-- end column name -->
              <!-- begin aggregation function -->
              <div class="form-group form-group-sm">
                <label
                  >{{ $t("titles.aggregation_function") }}
                  <Tooltip :title="$t('hints.aggregation_function')"
                /></label>
                <div class="input-group">
                  <select
                    :value="
                      dataItemProperty(entry.item, 'aggregationFunctionName')
                    "
                    @input="
                      dataItemProperty(
                        entry.item,
                        'aggregationFunctionName',
                        $event.target.value
                      )
                    "
                    class="form-control"
                  >
                    <option value="">{{ $tc("none", 1) }}</option>
                    <option
                      :value="functionName"
                      v-for="(functionName, ix) in functionList"
                      :key="ix"
                    >
                      {{ functionName }}
                    </option>
                  </select>
                  <div
                    class="input-group-addon btn"
                    @click.stop.prevent="
                      onResetItem($event, 'aggregationFunctionName', entry.item)
                    "
                  >
                    <i class="fa fa-close"></i>
                  </div>
                </div>
              </div>
              <!-- end aggregation function -->

              <!-- begin column format -->
              <div class="form-group form-group-sm">
                <label for=""
                  >{{ $t("format") }} <Tooltip :title="$t('custom_format')"
                /></label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    :value="dataItemProperty(entry.item, 'columnFormat')"
                    @input="
                      dataItemProperty(
                        entry.item,
                        'columnFormat',
                        $event.target.value
                      )
                    "
                  />
                  <div
                    class="input-group-addon btn"
                    @click.stop.prevent="
                      onResetItem($event, 'columnFormat', entry.item)
                    "
                  >
                    <i class="fa fa-close"></i>
                  </div>
                </div>
              </div>
              <!-- end column format -->
            </div>
          </template>
          <template #info><div /></template>
        </DataListForm>
      </div>
      <!-- end column list -->
    </div>
    <div class="footer">
      <span
        class="btn btn-xs btn-default pull-left"
        @click.stop.prevent="remove"
        :title="$t('remove')"
      >
        <span class=""> <i class="fa fa-trash"></i> {{ $t("remove") }} </span>
      </span>
      <span class="pull-right">
        <span
          class="btn btn-xs btn-primary"
          @click.stop.prevent="save(true)"
          style="margin-left: 10px"
          :disabled="!isValid"
        >
          <span><i class="fa fa-check"></i> {{ $t("save") }}</span>
        </span>
      </span>
    </div>
  </section>
</template>

<script>
import DataListForm from "@/components/control-sidebar/property-editors/data-list-form.vue";
import JSONPathPicker from "@/components/control-sidebar/property-editors/json-path-picker.vue";
import Tooltip from "@/components/tooltip.vue";
import {FunctionList} from "@/utils/stats.js";

const defEntry = (name) => ({
  name: name,
  groupByExpression: 'moment(time).format("DD/MM HH:00")',
  filterExpression: "",
  groupByOrder: "asc",
  dataList: []
});

const defGroupByEntry = () => ({
  data_id: "",
  date_time: "",
  time: "",
  value: ""
});
export default {
  name: "NamedQueryForm",
  props: {
    queryName: {
      type: String,
      required: true
    }
  },
  components: {
    DataListForm,
    JSONPathPicker,
    Tooltip
  },
  data() {
    return {
      entry: null
    };
  },
  computed: {
    entryName: {
      set(value) {
        this.entry.name = this.$utils.asPropertyName(value);
      },
      get() {
        return this.entry.name;
      }
    },
    functionList: FunctionList,
    groupByOrder: {
      set(value) {
        this.$set(this.entry, "groupByOrder", value);
      },
      get() {
        return this?.entry?.groupByOrder || "asc";
      }
    },
    filterExpression: {
      set(value) {
        this.$set(this.entry, "filterExpression", value);
      },
      get() {
        return this?.entry?.filterExpression || "";
      }
    },
    groupByExpression: {
      set(value) {
        this.$set(this.entry, "groupByExpression", value);
      },
      get() {
        return this?.entry?.groupByExpression || "";
      }
    },
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    template() {
      return this?.draft?.template;
    },
    defGroupByEntry: defGroupByEntry,
    aggregationFunctionNameList() {
      return (this?.entry?.dataList || []).map(
        ({aggregationFunctionName}) => aggregationFunctionName ?? ""
      );
    },
    isValid() {
      return (
        this?.entry?.dataList?.length &&
        ((this.groupByExpression &&
          this.aggregationFunctionNameList.every(
            (i) => this.$utils.trim(i) != ""
          )) ||
          (!this.groupByExpression &&
            this.aggregationFunctionNameList.every(
              (i) => this.$utils.trim(i) == ""
            )))
      );
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"] || [];
    }
  },
  methods: {
    dataItemProperty(item, attr, value) {
      if (
        !this.entry ||
        !this.entry.dataList.length ||
        !item ||
        !item.data_id ||
        !attr ||
        item.index === undefined ||
        item.index < 0 ||
        item.index > this.entry.dataList.length - 1
      )
        return;
      if (value !== undefined) {
        this.$set(this.entry.dataList[item.index], attr, value);
      }
      return this.entry.dataList[item.index][attr];
    },
    save(close) {
      if (!this.isValid) return;
      this.$emit("save", {
        name: this.queryName,
        entry: this.entry
      });
      if (close) this.$emit("cancel");
    },
    remove() {
      this.$utils
        .confirmRemoval(this, {
          item: this.entry,
          type: this.$t("titles.named_query")
        })
        .then((ok) => {
          if (!ok) return;
          this.$emit("remove", this.queryName);
        });
    },
    dataListParser(lst) {
      return (lst || []).filter(
        ({is_local, history_enabled, memory_size}) =>
          !is_local && history_enabled && memory_size == 1
      );
    },
    onResetItem($event, attr, item) {
      this.dataItemProperty(item, attr, "");
      let $el = $event.target.parentNode.querySelector("input");
      if (!$el)
        $el = $event.target.parentNode.parentNode.querySelector("input");
      if (!$el) return;
      this.$nextTick(() => {
        if ($el) $el.focus(); // double check since it might disapear
      });
    },
    getDataAt(ix) {
      let lst = this?.entry?.dataList || [];
      if (lst.length && ix >= 0 && ix < lst.length) {
        return this.dataList.find(
          ({id}) => parseInt(id) == parseInt(lst[ix].data_id)
        );
      }
      return null;
    },
    getDataNameAt(ix) {
      return this.getDataAt(ix)?.name || "";
    },
    getDataTitleAt(ix) {
      let data = this.getDataAt(ix);
      if (!data) return "";
      let txt = `#id: ${data.id} - ${data.name}`;
      if (data?.device) {
        txt += `\ndevice: ${data?.device?.id} - ${data?.device.name}`;
        if (data?.device?.connector) {
          txt += `\nconnector: ${data?.device?.connector?.id} - ${data?.device?.connector?.name}`;
        }
      }
      return txt;
    },
    onNewColumn() {
      const n = this.entry.dataList.length;
      this.$nextTick(() => {
        if (this.entry.dataList.length > n) {
          let ix = this.entry.dataList.length - 1;
          let item = this.entry.dataList[ix];
          this.dataItemProperty(
            item,
            "aggregationFunctionName",
            this.groupByExpression ? "average" : ""
          );
          let data = this.getDataAt(ix);
          if (data) {
            this.dataItemProperty(
              item,
              "columnFormat",
              this.$root.$formatter.dataFormatPattern(data)
            );
          }
        }
      });
    },
    copyToClipboard() {
      if (!this.entryName) return;
      this.$utils.toClipboard(this.entryName);
      this.$emit("active");
      let infoHtml = this.$t("item_copied");
      let options = {
        type: "success",
        icon: "copy",
        iconPack: "fontawesome",
        singleton: true,
        duration: 10000, // 10s
        position: "bottom-right",
        action: {
          icon: "fa-close",
          onClick: (e, me) => {
            me.goAway(0);
          }
        }
      };
      this.$toasted.clear();
      this.$toasted.show(infoHtml, options);
    }
  },
  created() {
    let list = this.template?.namedQueries ?? [];
    let entry = list.find(({name}) => name == this.queryName);
    entry = {
      ...defEntry(this.queryName),
      ...(entry && JSON.parse(JSON.stringify(entry)))
    };
    this.$set(this, "entry", entry);
  },
  beforeCreate() {}
};
</script>

<style scoped>
section {
  padding-bottom: 20px;
}
.footer {
  position: relative;
  clear: both;
  padding: 10px 10px 20px 10px;
}
.footer::after,
.footer::before {
  clear: both;
}

.columns-container {
  padding: 0 10px;
}
.column-form {
  padding: 5px 15px 5px 15px;
  margin-bottom: 20px;
  border-radius: 0 0 6px 6px;
}

.column-form:hover {
  background-image: linear-gradient(white, whitesmoke);
}
</style>
