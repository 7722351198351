<template>
  <FileExplorer
    ref="fileExplorer"
    :items="list"
    :modal="modal"
    :multiSelection="multiSelection"
    :title="`${maxResult} ${$tc('screen', maxResult > 1 ? 2 : 1)}`"
    :maxResult="maxResult"
    :discarded="discarded"
    :sidebarEnabled="!$utils.isMobile()"
    @change="tree = $event"
    @open="onOpen"
    @close="onClose"
    @drop="resetSearch"
    @beforeRestore="resetSearch"
    @sidebarResize="onSidebarResize"
    @move="onItemsMoved"
    dbKey="tree_screen"
  >
    <template #search>
      <div style="padding: 15px 10px 5px 10px; position: relative">
        <SearchableTableQueryInput
          ref="query"
          v-if="list && list.length && $refs.stbl"
          v-model="query"
          :createCommand="$refs.stbl.createCommand"
          @create="create"
        >
          <template #customFilters>
            <section>
              <div v-if="recentList.length">
                <span v-for="(screen, ix) in recentList" :key="screen.id">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-xs"
                    @click="onCommand('select', screen)"
                    :title="
                      `ID: ${screen.id}\nLast Update: ${screen.ts}\nVersion: ${screen.revision_code}`
                    "
                  >
                    {{ $utils.proper(screen.name) }}
                  </a>
                  <span class="sep" v-if="ix != recentList.length - 1">
                    |
                  </span>
                </span>
              </div>
            </section>
          </template>
          <template #extraButtons>
            <template v-if="multiSelection.values.length">
              <button
                v-if="trashCanSelected"
                class="btn btn-default"
                :title="$t('restore')"
                @click="
                  onCommand('command', {
                    name: 'restore',
                    target: multiSelection.values
                  })
                "
              >
                <i class="fa fa-undo" />
              </button>
              <button
                v-if="canRemoveItems"
                class="btn btn-default"
                :title="
                  `${
                    trashCanSelected
                      ? $tc('remove_permanently', 1)
                      : $t('remove')
                  }\n${$t('mass_remove')}`
                "
                @click="
                  onCommand('command', {
                    name: 'remove',
                    target: multiSelection.values
                  })
                "
              >
                <i class="fa fa-trash-o" />
              </button>
            </template>
          </template>
          <template #statistics>
            <ResourceStatistics
              resource="screen"
              :total="nTotal"
              :showing="nShowing"
              icon="fa fa-desktop"
            />
          </template>
        </SearchableTableQueryInput>
        <div v-if="msgBoard" class="alert alert-default msg-board">
          {{ msgBoard }}
        </div>
      </div>
    </template>
    <template #files>
      <SearchableTable
        :key="selectedNode || 'root'"
        class="table-container fade-in"
        :show="$refs.fileExplorer && $refs.fileExplorer.ready"
        :class="
          $refs.fileExplorer && $refs.fileExplorer.ready
            ? 'easy-show'
            : 'easy-hide'
        "
        :items="filteredItems"
        :fields="fields"
        :commands="commands"
        :pagination="modal ? false : pagination"
        :maxResult="maxResult"
        :multiColumnOrder="false"
        :clientSort="true"
        :deepSearch="false"
        :pageJump="false"
        :multiSelection="multiSelection"
        :searchEnabled="modal ? true : false"
        @select="onCommand('select', $event)"
        @command="onCommand('command', $event)"
        @loadNextPage="onCommand('loadNextPage', $event)"
        @multiColumnSort="onCommand('multiColumnSort', $event)"
        @nItems="maxResult = $event"
        ref="stbl"
        :style="{
          'margin-top': $refs.stbl && $refs.stbl.showPagination ? '-6px' : '0'
        }"
      >
        <template #paginationBefore>
          <div
            v-if="tree && !tree.show"
            class="btn-sidepanel-toggle"
            :class="{'pull-left': pagination}"
          >
            <div
              class="btn btn-default hidden-xs"
              @click="$refs.fileExplorer.toggle"
            >
              <i class="fa fa-list"></i>
            </div>
          </div>
        </template>
        <template #id="entry" v-if="tree">
          <div
            class="jstree-draggable"
            draggable="true"
            :data-item-id="entry.item.id"
            :data-item-name="screenName(entry.item)"
            @mousedown.stop.prevent="$refs.fileExplorer.dragStart"
            :title="
              `#${entry.item.id} - ${entry.item.description ||
                entry.item.name}${(entry.item.portal_data &&
                entry.item.portal_data.excluded_at &&
                '\n' + $tc('remove_permanently', 2)) ||
                ''}`
            "
          >
            <i :class="draggableIcon"></i>
            <span> {{ entry.item.id }}</span>
          </div>
        </template>
        <template #name="entry">
          <span
            v-bind:class="entry.item.id < 0 ? 'text-danger' : ''"
            :title="
              `#${entry.item.id} - ${entry.item.description ||
                entry.item.name}${(entry.item.portal_data &&
                entry.item.portal_data.excluded_at &&
                '\n' + $tc('remove_permanently', 2)) ||
                ''}`
            "
            >{{ screenName(entry.item) }}</span
          >
          <sup v-if="entry.item.id < 0">*</sup>
        </template>
        <template #empty v-if="list && list.length && !maxResult">
          <div>
            <div class="alert alert-default">
              <div class="h4">{{ $t("no_result_found") }}</div>
            </div>
          </div>
        </template>
      </SearchableTable>
    </template>
  </FileExplorer>
</template>

<script>
import {debounce} from "lodash";
import ResourceStatistics from "@/components/statistics/resource-statistics.vue";
import SearchableTable from "@/components/searchable-table.vue";
import SearchableTableQueryInput from "@/components/searchable-table-query-input.vue";
import FileExplorer from "@/components/editor/file-explorer.vue";

const initialData = () => ({
  tree: null,
  maxResult: 0,
  filtering: false
});

export default {
  name: "ScreenSearchTable",
  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    pagination: {
      type: Boolean,
      required: false,
      default: false
    },
    modal: {
      type: Boolean,
      required: false,
      default: false
    },
    multiSelection: {
      type: Object,
      required: false,
      default: () => ({key: null, values: []})
    }
  },
  components: {
    ResourceStatistics,
    SearchableTable,
    SearchableTableQueryInput,
    FileExplorer
  },
  data: initialData,
  computed: {
    recoverablePeriod() {
      return this.$root.config.recoverable_period || 30;
    },
    isContractOwner() {
      return this.$store.getters["user/loggedUser"].is_root;
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    fields() {
      return [
        {
          name: "id",
          title: "id",
          hint: (item) => {
            return `${item.id} ${item.name} ${
              item?.portal_data?.excluded_at
                ? "\n" + this.$tc("remove_permanently", 2)
                : ""
            }`;
          }
        },
        {
          name: "name",
          title: "screen",
          hint: (item) => {
            return `${item.id} ${item.name} ${
              item?.portal_data?.excluded_at
                ? "\n" + this.$tc("remove_permanently", 2)
                : ""
            }`;
          }
        },
        {
          name: "connector",
          title: "connector",
          hint: (item) =>
            `#${item.id} - ${item.name} ${
              item?.portal_data?.excluded_at
                ? "\n" + this.$tc("remove_permanently", 2)
                : ""
            }`,
          // style: { "white-space": "nowrap" },
          parser: (item) => {
            let name = item?.reference_connectors?.length
              ? item?.reference_connectors[0]?.name
              : "";
            if (!name) {
              let refMap = this.refMapGetter(item.id);
              if (refMap && refMap.conn1) {
                name = this.connectorList.find(({id}) => id == refMap.conn1)
                  ?.name;
              }
            }
            return name || "-";
          }
        },
        {
          name: "revision_code",
          title: "version",
          hint: (item) => {
            return `${item.id} ${item.name} ${
              item?.portal_data?.excluded_at
                ? "\n" + this.$tc("remove_permanently", 2)
                : ""
            }`;
          },
          style: {"text-align": "center"}
        },
        {
          name: "homepage",
          title: "Home page",
          style: {"text-align": "center"},
          hint: (item) => {
            return `${item.id} ${item.name} ${
              item?.portal_data?.excluded_at
                ? "\n" + this.$tc("remove_permanently", 2)
                : ""
            }`;
          },
          parser: (item) => {
            if (item.homepage === undefined) {
              item.homepage = this.findInContract(item);
            }
            const v = item.homepage ? true : false;
            return `${String.fromCharCode(123 + parseInt(v ? 0 : 1))} ${this.$t(
              v ? "yes" : "no"
            )} ${v ? "true" : "false"}`;
          },
          format: (value) => {
            return `${value}`.indexOf("true") >= 0
              ? "<span class='fa fa-check text-success'/>"
              : "-";
          }
        },
        {
          name: "updated_at",
          title: "updated_at",
          style: {"text-align": "center"},
          visible: () => !this.trashCanSelected,
          parser: (item) => {
            return item.updated_at
              ? `${new Date(item.updated_at).getTime()};${this.$dt.format(
                  item.updated_at
                )}`
              : "";
          },
          format: (value) => {
            return (value && value.split(";")[1]) || "-";
          }
        },
        {
          name: "deleted_at",
          title: "deleted_at",
          style: {"text-align": "center"},
          hint: (item) => {
            return `${item.id} ${item.name} ${
              item?.portal_data?.excluded_at
                ? "\n" + this.$tc("remove_permanently", 2)
                : ""
            }`;
          },
          visible: () => this.trashCanSelected,
          format: (value) => {
            return value ? this.$dt.format(value) : "-";
          }
        }
      ];
    },
    commands() {
      return [
        {
          name: "create",
          title: "new",
          icon: "fa fa-plus",
          enabled: () => {
            return this.$can("manage", "TelaCustomizadaEscrita");
          },
          commands: [{name: "import", title: "import", icon: "fa fa-plus"}]
        },
        {
          name: "edit",
          title: "edit",
          icon: "fa fa-pencil",
          enabled: () => {
            return (
              !this.nSelected && this.$can("manage", "TelaCustomizadaEscrita")
            );
          }
        },
        {
          name: "clone",
          title: "clone",
          icon: "fa fa-copy",
          enabled: () => {
            return (
              !this.nSelected && this.$can("manage", "TelaCustomizadaEscrita")
            );
          }
        },
        {
          name: "download",
          title: "download",
          icon: "fa fa-download",
          enabled: () => {
            return (
              !this.nSelected && this.$can("manage", "TelaCustomizadaEscrita")
            );
          }
        },
        {
          name: "publish",
          title: this.$tc("publish", 1),
          icon: "fa fa-cloud-upload",
          enabled: () => {
            return (
              !this.nSelected && this.$can("manage", "TelaCustomizadaEscrita")
            );
          }
        },
        {
          name: "restore",
          title: (item) => {
            return item.deleted_at
              ? this.$t("restore")
              : `${this.$t("restore_screen")} ${this.$t("and")} ${this.$t(
                  "edit"
                )}`;
          },
          icon: (item) =>
            item != this.restoring
              ? "fa fa-undo"
              : "fa fa-refresh text-warning fa-spin",
          enabled: (item) => {
            return (
              item.id > 0 &&
              !this.restoring &&
              !this.nSelected &&
              this.$can("manage", "TelaCustomizadaEscrita")
            );
          }
        },
        {
          name: "remove",
          title: this.trashCanSelected
            ? this.$tc("remove_permanently", 2)
            : "remove",
          icon: "fa fa-trash",
          enabled: (item) => {
            return (
              !item?.portal_data?.excluded_at &&
              !this.nSelected &&
              this.$can("manage", "TelaCustomizadaEscrita")
            );
          }
        }
      ];
    },
    folderMode() {
      return this.tree && this.tree.show ? true : false;
    },
    selectedNode() {
      return (this.folderMode && this.tree.selectedNode) || "";
    },
    list() {
      return (this.items || []).filter(
        (i) => !i.public && (!i.portal_data || i.portal_data.type !== "script")
      );
    },
    activeList() {
      return (this.list || []).filter(
        (i) =>
          this.isContractOwner || !i.portal_data || !i.portal_data.excluded_at
      );
    },
    filteredItems() {
      let now = new Date().toISOString();
      let lst =
        this.filtering && this.selectedNode
          ? this.$refs.stbl.search(this.list || [])
          : this.list || [];
      return lst
        .filter((item) => {
          return !this.tree ||
            (!this.isContractOwner && item?.portal_data?.excluded_at)
            ? false
            : !this.tree.show && item.deleted_at
            ? false
            : "public" in item
            ? !this.tree.show ||
              this.tree.selectedNode === "" ||
              this.tree.selectedNode === this.tree.leaves[item.id]
            : true;
        })
        .map((item) => {
          return item?.portal_data?.excluded_at
            ? {...item, class: "opaque"}
            : item;
        })
        .sort((a, b) => {
          let _a =
            a?.updated_at || `${now}${this.$utils.sprintf("%05d", a.id)}`;
          let _b =
            b?.updated_at || `${now}${this.$utils.sprintf("%05d", b.id)}`;
          return _a > _b ? -1 : _b > _a ? 1 : 0;
        });
    },
    nTotal() {
      return this.folderMode &&
        this.selectedNode &&
        this.selectedNode == "trash_can"
        ? this.discarded.length || 0
        : this.activeList.length - this.discarded.length;
    },
    nShowing() {
      const n =
        (this.$refs.stbl &&
          this.$refs.stbl.itemList &&
          this.$refs.stbl.itemList.length) ||
        0;
      // the selectedNode and maxResult are here just to trigger reactivity
      return (this.maxResult && this.selectedNode && n) || n;
    },
    refMapGetter() {
      return this.$store.getters["dashboard/screenRefMap"];
    },
    connectorList() {
      return this.$store.getters["dashboard/connectorList"] || [];
    },
    recentList() {
      return (this.$store.getters["dashboard/editorSettings"].recent || [])
        .map((item) => {
          return {
            ...{ts: item.updated_at, name: null},
            ...(this.list || []).find(({id}) => id == item.screenId)
          };
        })
        .filter(({name, deleted_at}) => (name && !deleted_at ? true : false));
      // .sort((a, b) => (a.ts > b.ts ? -1 : b.ts > a.ts ? 1 : 0));
    },
    query: {
      set(value) {
        if (this.$refs.stbl) {
          this.$refs.stbl.query = value;
        }
        this.filtering = this.$utils.trim(this.$refs.stbl.query) !== "";
        // TODO: in order to show only filtered folders, it should calls the explorer filterNodesByLeafId thought a debouced function
        this.search();
      },
      get() {
        return this?.$refs?.stbl?.query || "";
      }
    },
    draggableIcon() {
      return this?.tree?.icons?.leaf || "fa fa-file-o";
    },
    nPrivateItems() {
      return (this.list || []).filter((screen) => !screen.public).length;
    },
    discarded() {
      return (this.activeList || [])
        .filter(({deleted_at}) => (deleted_at ? true : false))
        .map(({id}) => id);
    },
    trashCanSelected() {
      return this?.tree?.show && this?.tree?.selectedNode == "trash_can";
    },
    nSelected() {
      return (this.multiSelection.values || []).length;
    },
    msgBoard() {
      let msg = [];
      if (this.trashCanSelected) {
        msg.push(
          this.$t("hints.cleaning_due_date", {days: this.recoverablePeriod})
        );
      }
      return msg.join(" | ");
    },
    canRemoveItems() {
      const nBlocked = (this.multiSelection.values || [])
        .map((i) => this.list.find(({id}) => parseInt(i) == parseInt(id)))
        .filter(({portal_data}) =>
          portal_data && portal_data.excluded_at ? true : false
        ).length;
      return this.nSelected && this.nSelected != nBlocked;
    }
  },
  watch: {
    items(n, o) {
      if (n && o && n.length != o.length && this.$refs.fileExplorer) {
        this.$nextTick(() => {
          if (n.length == this.$refs.fileExplorer.items.length) {
            if (this.tree.show && this.$refs.fileExplorer.show) {
              this.$refs.fileExplorer.show();
            }
          }
        });
      }
    },
    folderMode(n) {
      if (this.$refs.stbl) this.$refs.stbl.save();
    }
  },
  methods: {
    screenName(screen) {
      let name = screen.name;
      if (screen.id < 0) {
        name = this.$t("new_screen");
        name = `${name} (${
          -1 * screen.id <= 9 ? "0" + -1 * screen.id : -1 * screen.id
        })`;
      }
      return name;
    },
    create(name) {
      this.onCommand("command", {name: name || "create"});
    },
    getItemsWithId(id) {
      let lst = Array.isArray(id) ? id : [id];
      return (lst || [])
        .map((screenId) =>
          this.items.find(({id}) => parseInt(id) === parseInt(screenId))
        )
        .filter((i) => (i ? true : false));
    },
    onItemsMoved($event) {
      if (!$event) return;
      if ($event.from == "trash_can") {
        this.restoreFromTrashCan(this.getItemsWithId($event.items)).then(
          (resp) => {
            if (resp) {
              this.$refs.fileExplorer.moveLeaves($event.items, $event.to);
            }
          }
        );
      } else if ($event.to == "trash_can") {
        this.$emit("command", {
          name: "remove",
          target: $event.items
        });
      }
    },
    onCommand(name, $event) {
      let payload = $event;
      if (this.modal) {
        this.command = {
          name: name,
          event: payload
        };
        if (this.$refs.fileExplorer) {
          this.$refs.fileExplorer.close();
        }
        return;
      }
      if ($event?.name == "create") {
        payload = {...payload};
        payload.selectedNode =
          this?.tree?.show &&
          this?.tree?.selectedNode &&
          this?.tree?.selectedNode != "trash_can"
            ? this.tree.selectedNode
            : "root";
      } else if (
        $event?.name == "restore" &&
        this.trashCanSelected &&
        payload.target
      ) {
        this.restoreFromTrashCan(
          this.getItemsWithId(payload.target.id ?? payload.target)
        );
        return;
      }
      this.$emit(name, payload);
    },
    restoreFromTrashCan(lst) {
      const _execute = (item) => {
        return new Promise((resolve) => {
          let payload = {
            ...item,
            deleted_at: null,
            portal_data: {...(item.portal_data || {}), excluded_at: null}
          };
          this.$parent.service.save(payload).then((result) => {
            if (result && result.id) {
              this.$refs.fileExplorer.moveLeaves([result.id]);
              this.$store.dispatch("dashboard/setScreen", result).then(() => {
                this.$emit("updateItem", result.id);
                resolve();
              });
            } else {
              resolve();
            }
          });
        });
      };
      return new Promise((resolve) => {
        if (!this.$parent.service || !this.$refs.fileExplorer || !lst?.length) {
          resolve(false);
          return;
        }
        if (lst.some(({deleted_at}) => (deleted_at ? true : false))) {
          let n = lst.length;
          this.$emit("loading", true);
          lst.forEach((screen) => {
            _execute(screen).then(() => {
              n--;
              if (!n) {
                this.$emit("loading", false);
                resolve(true);
              }
            });
          });
        }
      });
    },
    findInContract(item) {
      const id = parseInt(item.id);
      const searchPage = this?.contract?.portal_data?.search_page || {};
      for (var p in searchPage) {
        if (parseInt(searchPage[p]?.screen_id) == id) {
          return p;
        }
      }
      const userTabs = this?.contract?.portal_data?.userTabs || [];
      let tab = userTabs.find(({screenId}) => parseInt(screenId) == id);
      return (tab && tab.title) || "";
    },
    onOpen() {
      this.$emit("open");
    },
    onClose() {
      if (this.command) {
        this.$emit(this.command.name, this.command.event);
      }
      this.$emit("close");
      this.command = null;
    },
    async search() {
      if (!this.$refs.fileExplorer) return;
      this._search =
        this._search ||
        debounce((filtering) => {
          let lst = filtering
            ? this.$refs.stbl.search(this.items).map(({id}) => id)
            : null;
          if (this.$refs.fileExplorer)
            this.$refs.fileExplorer.findNodesByLeafId(lst);
        }, 500);
      this._search(this.filtering);
    },
    resetSearch() {
      this.$emit("clearSelection");
      if (this.$refs.query) this.$refs.query.resetQuery();
    },
    onSidebarResize() {
      this.$nextTick(() => {
        if (this.$refs.stbl) {
          this.$refs.stbl.syncColumnResizeHandle();
        }
      });
    }
  }
};
</script>

<style scoped>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.table-container {
  min-height: 100px;
  max-height: 100%;
  height: calc(100%);
  overflow: visible;
}

@media (max-width: 768px) {
  .table-container {
    overflow: auto;
  }
}
.modal-body {
  max-height: 600px;
  overflow: auto;
}

.modal-body > .table-container {
  padding: 0 15px;
}

.sep {
  color: #bbbbbb;
}

.btn-sidepanel-toggle {
  margin: 0 25px 0 10px;
}

.jstree-draggable {
  white-space: nowrap;
  min-width: 40px;
  display: inline-block;
}
.jstree-draggable > i {
  margin-right: 6px;
}

.easy-hide {
  opacity: 0;
}
.easy-show {
  opacity: 1;
}
.fade-in {
  transition: opacity 0.2s ease;
}

.alert-default {
  background-color: whitesmoke;
  color: #555;
  text-shadow: #bbb 0px 1px;
  text-align: center;
}
.skin-dark .alert-default {
  background-color: var(--skin-dark-dark);
  border-color: var(--skin-dark-darker);
  color: var(--skin-dark-light);
  text-shadow: inherit;
}
section.searchable-table::v-deep > div > table > tbody > tr.opaque {
  /* opacity: 0.6;
  color: #a94442; */
  color: #888;
}
.msg-board {
  position: absolute;
  width: 100%;
  bottom: -50px;
  padding: 5px;
  border-radius: 5px;
  margin-left: -15px;
}
</style>
