<template>
  <section>
    <TogglePanel
      :title="$tc('source', 1)"
      layout="compressed"
      accordion="extended_properties_panel"
      hint="hints.source_equipment_options"
    >
      <div class="form-group form-group-sm">
        <select class="form-control" v-model="equipmentSource">
          <option value="dynamic">{{ $t("titles.dynamic") }}</option>
          <option value="custom">{{ $tc("customized", 1) }}</option>
        </select>
      </div>
      <div
        class="form-group form-group-sm"
        :class="{'v-select-open': connectorSelectionOpen}"
        v-if="
          equipmentSource != 'dynamic' && equipmentSourceConnectorList.length
        "
      >
        <label for="">{{ $tc("connector", 1) }}</label>
        <v-select
          class="v-select"
          v-model="equipmentSourceConnectorId"
          label="name"
          :options="equipmentSourceConnectorList"
          :reduce="(i) => i.id"
          @open="connectorSelectionOpen = true"
          @close="connectorSelectionOpen = false"
        >
          <span slot="no-options">{{ $t("no_result_found") }}</span>
        </v-select>
      </div>
      <div
        class="form-group form-group-sm"
        :class="{'v-select-open': deviceSelectionOpen}"
        v-if="
          equipmentSourceConnectorList.length &&
            equipmentSourceDeviceList.length
        "
      >
        <label for="">{{ $tc("device", 1) }}</label>
        <v-select
          class="v-select"
          v-model="equipmentSourceDeviceId"
          label="name"
          :options="equipmentSourceDeviceList"
          :reduce="(i) => i.id"
          @open="deviceSelectionOpen = true"
          @close="deviceSelectionOpen = false"
        >
          <span slot="no-options">{{ $t("no_result_found") }}</span>
        </v-select>
      </div>
    </TogglePanel>
    <TogglePanel
      :title="$tc('controls', 1)"
      layout="compressed"
      accordion="extended_properties_panel"
      style="z-index:100"
    >
      <div class="form-group">
        <label class="checkbox-inline" for="showRequiredFields">
          <input
            type="checkbox"
            v-model="showRequiredFields"
            id="showRequiredFields"
          />
          <span> {{ $t("show") }} {{ $tc("required", 2).toLowerCase() }} </span>
        </label>
      </div>
      <div class="form-group">
        <label class="checkbox-inline" for="showOptionalFields">
          <input
            type="checkbox"
            v-model="showOptionalFields"
            id="showOptionalFields"
          />
          <span> {{ $t("show") }} {{ $tc("optional", 2).toLowerCase() }} </span>
        </label>
      </div>
      <AlertForm v-model="confirmation" />
      <div class="form-group">
        <label class="checkbox-inline" for="displayInline">
          <input type="checkbox" v-model="displayInline" id="displayInline" />
          <span>
            {{ $t("display_inline") }}
          </span>
        </label>
        <span class="pull-right clicable" v-if="displayInline">
          <i
            :class="
              !display_inline_collapsed
                ? 'fa fa-caret-square-o-down'
                : 'fa fa-caret-square-o-up'
            "
            @click.stop.prevent="
              display_inline_collapsed = !display_inline_collapsed
            "
          ></i>
        </span>
      </div>
      <div class="form-group" v-if="displayInline && display_inline_collapsed">
        <label for="columnsInline">
          {{ $t("columns_inline") }}
        </label>
        <input
          type="number"
          id="columnsInline"
          class="form-control"
          v-model="columnsInline"
          min="1"
          max="3"
        />
      </div>
    </TogglePanel>
    <TogglePanel
      :title="$tc('field', 2)"
      layout="compressed"
      accordion="extended_properties_panel"
      style="z-index:100"
    >
      <div>
        <draggable class="list-group" v-model="filteredFields">
          <div
            class="list-group-item"
            :title="item.description"
            v-for="(item, ix) in filteredFields"
            :key="ix"
          >
            <div :class="classText(item)" :title="titleItem(item)">
              {{ item.title || item.name }}
            </div>
            <div>
              <i
                class="btn btn-xs fa clicable"
                :class="classVisible(item)"
                @click="toggleVisible(item)"
                :disabled="disabledVisible(item)"
                :title="$t('panel_custom_field_visible_title')"
              ></i>
              <i
                class="btn btn-xs fa clicable"
                :class="classRequired(item)"
                @click="toggleRequired(item)"
                :disabled="disabledRequired(item)"
                :title="$t('panel_custom_field_required_title')"
              ></i>
            </div>
          </div>
        </draggable>
      </div>
    </TogglePanel>
  </section>
</template>

<script>
import AlertForm from "@/components/editor/alert-form.vue";
import draggable from "vuedraggable";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
// import Tooltip from "@/components/tooltip";
export default {
  name: "DetailFormExtendedPropertiesPanel",
  components: {
    AlertForm,
    draggable,
    TogglePanel
    // Tooltip
  },
  data() {
    return {
      fields: [],
      display_inline_collapsed: false,
      connectorSelectionOpen: false,
      deviceSelectionOpen: false,
      iEquipmentSource: "dynamic"
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    screenId() {
      return (this.$store.getters["dashboard/draft"] || {screenId: ""})
        .screenId;
    },
    connectorId() {
      if (this.mode == "editor") {
        return this.$store.getters["dashboard/refConnectorId"];
      } else {
        return this.$store.getters["dashboard/dashboardEquipmentId"];
      }
    },
    connectorCustomFields() {
      if (!this.connectorId) return null;
      // Obtém o conector de referência da Tela
      let connector = (
        this.$store.getters["dashboard/connectorList"] || []
      ).find(({id}) => id == this.connectorId);
      let custom_fields =
        (this.contract &&
          this.contract.portal_data &&
          this.contract.portal_data.equipment_extended_properties) ||
        [];
      let connector_custom_fields =
        (connector &&
          connector.user_data &&
          connector.user_data.extended_properties) ||
        {};
      return custom_fields.slice(0).map((field) => {
        const connector_field_value = connector_custom_fields[field.name];
        if (connector_field_value) {
          field.value = connector_field_value;
        }
        return field;
      });
    },
    showRequiredFields: {
      set(value) {
        if (this.panel && this.panel.options) {
          let panel = this.panel;
          panel.options.showRequiredFields = value;
          this.savePanel(panel);
        }
      },
      get() {
        return this?.panel?.options?.showRequiredFields ?? false;
      }
    },
    showOptionalFields: {
      set(value) {
        if (this.panel && this.panel.options) {
          let panel = this.panel;
          panel.options.showOptionalFields = value;
          this.savePanel(panel);
        }
      },
      get() {
        return this?.panel?.options?.showOptionalFields ?? false;
      }
    },
    displayInline: {
      set(value) {
        if (value) this.display_inline_collapsed = true;
        this.panel.options.displayInline = value;
        this.savePanel();
      },
      get() {
        return this?.panel?.options?.displayInline ?? false;
      }
    },
    columnsInline: {
      set(value) {
        this.panel.options.columnsInline = value;
        this.savePanel();
      },
      get() {
        return this?.panel?.options?.columnsInline ?? 3;
      }
    },
    confirmation: {
      set(value) {
        let panel = this.panel;
        let ix = (panel?.options?.controls || []).findIndex((item) =>
          (item?.on?.click?.actions || []).some(
            ({type}) => type == "form:submit"
          )
        );
        if (ix == -1) return;
        let ctrl = (panel?.options?.controls || [])[ix];
        ctrl.on = ctrl.on || {};
        ctrl.on.click = ctrl.on.click || {};
        ctrl.on.click.confirmation = {
          ...(ctrl?.on?.click?.confirmation || null),
          ...(value || null)
        };
        this.$set(panel.options?.controls, ix, ctrl);
        this.savePanel(panel);
      },
      get() {
        return (
          (this?.panel?.options?.controls || []).find((item) =>
            (item?.on?.click?.actions || []).some(
              ({type}) => type == "form:submit"
            )
          )?.on?.click?.confirmation ?? null
        );
      }
    },
    filteredFields: {
      set(value) {
        value = value.map((item, idx) => {
          return {
            ...item,
            index: idx
          };
        });
        this.fields = value;
        let panel = this.panel;
        panel.options.fields = value;
        this.savePanel(panel);
      },
      get() {
        return this.fields;
      }
    },
    equipmentSource: {
      set(value) {
        if (this.panel && this.panel.options) {
          let panel = this.panel;
          this.iEquipmentSource = value;
          panel.options.connector_id = "";
          panel.options.device_id = "";
          if (value !== "dynamic") {
            const refConnId = this.$store.getters["dashboard/refConnectorId"];
            if (refConnId) {
              let connector = this.$store.getters[
                "dashboard/connectorList"
              ].find(({id}) => parseInt(id) == parseInt(refConnId));
              if (connector && !connector.base_model) {
                panel.options.connector_id = connector.id;
              }
            }
          }
          this.savePanel(panel);
        }
      },
      get() {
        return this.iEquipmentSource || "dynamic";
      }
    },
    equipmentSourceConnectorId: {
      set(value) {
        if (this.panel && this.panel.options) {
          let panel = this.panel;
          panel.options.connector_id = value;
          panel.options.device_id = "";
          this.savePanel(panel);
        }
      },
      get() {
        return this.panel.options.connector_id || "";
      }
    },
    equipmentSourceDeviceId: {
      set(value) {
        if (this.panel && this.panel.options) {
          let panel = this.panel;
          panel.options.device_id = value;
          this.savePanel(panel);
        }
      },
      get() {
        return this.panel.options.device_id || "";
      }
    },
    equipmentSourceConnectorList() {
      if (this.equipmentSource == "dynamic") return [];
      // TODO: Validate model field
      return (this.$store.getters["dashboard/connectorList"] || []).filter(
        ({base_model}) => !base_model
      );
    },
    equipmentSourceDeviceList() {
      if (this.equipmentSource == "dynamic" || !this.equipmentSourceConnectorId)
        return [];
      return (this.$store.getters["dashboard/deviceList"] || []).filter(
        ({connector}) =>
          parseInt(connector.id) == parseInt(this.equipmentSourceConnectorId)
      );
    },
    panelFields() {
      return this.panel && this.panel.options.fields;
    },
    panelCustomFields() {
      return (
        this.connectorCustomFields ||
        (this.contract &&
          this.contract.portal_data &&
          this.contract.portal_data.equipment_extended_properties) ||
        []
      );
    }
  },
  watch: {
    panelFields: {
      handler(n) {
        if (!n || this.fields.length) return;
        this.setup();
      },
      immediate: true
    }
  },
  methods: {
    reset() {
      this.src = "";
      this.$nextTick(() => {
        this.$refs.inp.focus();
      });
    },
    setup() {
      let addon = {
        panel_required: false,
        visible: true
      };
      let fields = this.panelCustomFields.map((item) => {
        const panel_field = this.panelFields.find(
          ({name}) => item.name == name
        );
        if (panel_field) {
          return {
            ...addon,
            ...item,
            ...panel_field
          };
        } else {
          return {
            ...addon,
            ...item
          };
        }
      });
      this.fields = this.fields
        .concat(fields)
        .sort((a, b) => a.index - b.index);
    },
    savePanel(panel) {
      this.$store.dispatch("dashboard/saveDraftPanel", {
        screenId: this.screenId,
        panel: panel ?? this.panel
      });
    },
    toggleVisible(item) {
      if (
        (item.required && !this.showRequiredFields) ||
        (!item.required && !this.showOptionalFields)
      ) {
        return;
      }
      item.visible = !item.visible;
      let panel = this.panel;
      panel.options.fields = this.fields.slice();
      this.savePanel(panel);
    },
    toggleRequired(item) {
      if (item.required) {
        return;
      }
      item.panel_required = !item.panel_required;
      let panel = this.panel;
      panel.options.fields = this.fields.slice();
      this.savePanel(panel);
    },
    disabledVisible(item) {
      return (
        (item.required && !this.showRequiredFields) ||
        (!item.required && !this.showOptionalFields)
      );
    },
    disabledRequired(item) {
      return (
        (item.required && !this.showRequiredFields) ||
        (!item.required && !this.showOptionalFields)
      );
    },
    classText(item) {
      let classes = "";
      if (item.required && !item.value) classes += " text-danger";
      if (
        (item.required && !this.showRequiredFields) ||
        (!item.required && !this.showOptionalFields)
      )
        classes += " disabled-item";
      return classes;
    },
    titleItem(item) {
      if (item.required && !item.value)
        return this.$t("required_custom_field_not_filled");
      return "";
    },
    classVisible(item) {
      if (
        (item.required && !this.showRequiredFields) ||
        (!item.required && !this.showOptionalFields)
      )
        return "fa-eye-slash";
      return item.visible ? "fa-eye" : "fa-eye-slash";
    },
    classRequired(item) {
      return item.required || item.panel_required
        ? "fa-asterisk"
        : "fa-circle-o";
    }
  },
  created() {
    if (
      this.equipmentSourceConnectorId ||
      (this.equipmentSourceConnectorId && this.equipmentSourceDeviceId)
    ) {
      this.iEquipmentSource = "custom";
    }
  }
};
</script>

<style scoped>
.checkbox-inline > span {
  margin-left: 3px;
  font-weight: 700;
}
.clicable {
  cursor: pointer;
}
.skin-dark .clicable:hover {
  opacity: 1;
  color: #fff;
}
.move-item:hover {
  cursor: move;
  opacity: 0.8;
}
.btn.btn-xs {
  min-width: 30px;
}
.disabled-item {
  font-style: italic;
  opacity: 0.5;
}
.v-select-open {
  min-height: 380px;
}

.list-group {
  padding: 0 5px;
}

.list-group-item {
  padding: 4px 0px 4px 4px;
  display: flex;
  justify-content: space-between;
}

.list-group-item:hover {
  cursor: move;
  opacity: 0.6;
}
</style>
